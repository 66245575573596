import React from "react";

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const Home = React.lazy(() => import('./pages/Home/index'));
const AboutUs = React.lazy(() => import("./pages/Pages/AboutUs"));
// const PageServices = React.lazy(() => import("./pages/Pages/PageServices"));
const PageBlogDetailTwo = React.lazy(() => import("./pages/Pages/Blog/PageBlogDetailTwo"));
const PageBlogList = React.lazy(() => import("./pages/Pages/Blog/PageBlogList"));
const Gis = React.lazy(() => import("./pages/Pages/Gis"));
const PageContactDetail = React.lazy(() => import("./pages/Pages/Contact"));

const routes = [
  { path: "/contact", component: PageContactDetail, isWithoutLayout: true, isTopbarDark: true },
  { path: "/page-error", component: PageError, isWithoutLayout: true },
  { path: "/about-us", component: AboutUs },
  { path: "/service", component: PageBlogDetailTwo },
  { path: "/services-and-solutions", component: PageBlogList, isTopbarDark: true },
  { path: "/gis", component: Gis, isTopbarDark: true },
  { path: "/", component: Home, isWithoutLayout: false, isTopbarDark: true, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;